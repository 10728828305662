import Vue from 'vue';
import App from '@/App.vue';
import router from '@/router';
import store from '@/store';
import vuetify from '@/plugins/vuetify';
import { Auth0Plugin } from './auth';
import RegisterCommonComponents from './registerCommonComponents';
import IconLibrary from '@/plugins/iconLibrary/plugin';
import Logger from '@/lib/logger';
import RoleCheck from '@/plugins/roles';
import ScrollTo from '@/plugins/ScrollTo';
import Toasted from 'vue-toasted';
import { Plugin as Fragment } from 'vue-fragment';

/* eslint-disable */
console.group('Environment Details:');
console.log(`Build: ${process.env.NODE_ENV}.`);
console.log(`App Environment: ${process.env.VUE_APP_ENV}.`);
// console.log(`GraphQL URI: ${process.env.VUE_APP_APOLLO_GRAPHQL_CLIENT_URI}.`);
console.groupEnd();
/* eslint-enable */

Vue.config.productionTip = false;

RegisterCommonComponents(Vue);

Vue.use(Auth0Plugin, {
  domain: process.env.VUE_APP_AUTH0_DOMAIN,
  clientId: process.env.VUE_APP_AUTH0_CLIENT_ID,
  audience: process.env.VUE_APP_AUTH0_API_IDENTIFIER,
  onRedirectCallback: (appState: { targetUrl: string }) => {
    router.push(appState && appState.targetUrl ? appState.targetUrl : window.location.pathname);
  },
});

Vue.use(Fragment);
Vue.use(IconLibrary);
Vue.use(RoleCheck);
Vue.use(ScrollTo);

Vue.use(Toasted, {
  position: 'bottom-center',
  theme: 'toasted-primary',
  duration: 5000,
  fullWidth: true,
});

if (process.env.NODE_ENV !== 'test') {
  Vue.prototype.$log = Logger;
}

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
